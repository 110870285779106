import { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { useConfigContext } from "./ConfigContext";
import { useLocation } from "react-router-dom";

export interface Announcement {
  id: string;
  title: string;
  body: string;
  link?: {
    text: string;
    url: string;
  };
  isUnread?: boolean;
}

interface AnnouncementsContextType {
  announcements: Array<Announcement>;
  markAnnouncementsAsRead: (announcementIds: Array<string>) => void;
}

const AnnouncementsContext = createContext<AnnouncementsContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

// Temporary hard-coded storage for announcements.
const ANNOUNCEMENTS: Array<Announcement> = [
  {
    id: "conversation-history-search",
    title: "New Conversation History Search",
    body: "Vectara introduces a semantic conversation search feature, enabling the identification of “unknown unknowns” in user inquiries. This allows developers to find instances where information was previously unknown and detect when users express frustration, all without predefined rules, leveraging Vectara's advanced semantic search capabilities.",
    link: {
      text: "Learn more",
      url: "https://vectara.com/blog/vectara-launches-2-powerful-new-generative-capabilities/"
    }
  },
  {
    id: "markdown-html-responses",
    title: "Introducing Markdown and HTML Responses",
    body: "Vectara now supports structured generative responses in Markdown and HTML formats, integrating references and metadata directly via URLs from document fields. This enhancement simplifies client-side parsing and reduces errors, enabling developers to easily reference document details like titles, page numbers, and URLs in their search responses.",
    link: {
      text: "Learn more",
      url: "https://vectara.com/blog/vectara-launches-2-powerful-new-generative-capabilities/"
    }
  },
  {
    id: "multilingual-reranker_v1",
    title: "Introducing the Multilingual Reranker_v1",
    body: "The new Multilingual Reranker_v1 significantly improves precision in RAG pipelines, offering ~10% improvement in NDCG for English datasets and ~30% for multilingual datasets. This state-of-the-art model excels in refining search results, ensuring more relevant outcomes across diverse linguistic environments.",
    link: {
      text: "Learn more",
      url: "https://vectara.com/blog/unlocking-the-state-of-the-art-reranker-introducing-the-vectara-multilingual-reranker_v1/"
    }
  }
];

export const AnnouncementsContextProvider = ({ children }: Props) => {
  const [announcements, setAnnouncements] = useState<Array<Announcement>>([]);
  const { config, setReadAnnouncements } = useConfigContext();
  const location = useLocation();

  // Update announcements state to reflect latest available announcements and un/read state
  // only when the user navigates to a different page.
  // We update lazily since we purposely want to maintain the current announcements a user sees when they enter a page.
  // If we eventually have a use case for real-time updates, we can add a real-time state separately and have only views
  // that are concerned with those updates use that new state.
  // The important thing is that all announcement states, lazy and (maybe eventually) real-time, are kept in this single source of truth context.
  useEffect(() => {
    fetchAndMarkAnnouncements();
  }, [location.pathname]);

  /**
   * Fetch announcements from some source and reconcile read/unread state
   * with client data obtained from config context.
   * Currently "fetches" announcements from ANNOUNCEMENTS constant defined in this file.
   *
   * When we want to get announcements from some API, we'll do it in this function.
   */
  const fetchAndMarkAnnouncements = () => {
    // Reverse the announcements list to display the latest first.
    const announcementsToDisplay = [...ANNOUNCEMENTS].reverse();
    const readAnnouncementIds = config?.readAnnouncementIds ?? {};

    announcementsToDisplay.forEach((announcementToDisplay) => {
      announcementToDisplay.isUnread = !readAnnouncementIds[announcementToDisplay.id];
    });

    setAnnouncements(announcementsToDisplay);
  };

  /**
   * Persist IDs of read announcements to client data.
   */
  const markAnnouncementsAsRead = (announcementIds: Array<string>) => {
    setReadAnnouncements(announcementIds);
  };

  return (
    <AnnouncementsContext.Provider value={{ announcements, markAnnouncementsAsRead }}>
      {children}
    </AnnouncementsContext.Provider>
  );
};

export const useAnnouncementsContext = () => {
  const context = useContext(AnnouncementsContext);
  if (context === undefined) {
    throw new Error("useAnnouncementsContext must be used within a AnnouncementsContextProvider");
  }
  return context;
};
