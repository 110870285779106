import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useApiContext } from "./ApiContext";
import { PricingPlan } from "../types/billing";
import { useUserContext } from "./UserContext";
import { ChangeCustomerPlan, ReadCurrentCustomerPlan } from "../admin/BillingEndpoint";
import { ReadCurrentIdOfPlan, ReadCustomerPlanDetails } from "../admin/PublicEndpoint";
import { StatusCode } from "../generated_protos/status_pb";
import { useNotificationsContext } from "./NotificationsContext";
import { getDueDate } from "../utils/getDueDate";
import { PlanName } from "../generated_protos/admin/admin_signup_pb";
import { FeatureType } from "../generated_protos/admin/admin_feature_pb";

interface PlanContextType {
  currentPlan?: PricingPlan;
  currentPlanName?: string;
  currentPlanId?: string;
  currentPlanCode?: StatusCode;
  growthCCPlan?: PricingPlan;
  growthCCPlanName?: string;
  growthFreePlanId: string;
  growthCCPlanId: string;
  scalePlanId: string;
  standardPlan?: PricingPlan;
  isOnFreePlan: boolean;
  isOnScalePlan: boolean;
  isOnBundlePlan: boolean;
  isSupportAvailable: boolean;
  switchPlan: (newPlanName: PlanName, newPlanAlias: string) => void;
  planEnumValue?: PlanName;
}

const PlanContext = createContext<PlanContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const PlanContextProvider = ({ children }: Props) => {
  const { customer, getJwt, updateEnabledFeatures, userDetails, enabledFeatures } = useUserContext();
  const { addNotification } = useNotificationsContext();
  const { AdminService, PublicAdminService } = useApiContext();

  const [growthCCPlan, setGrowthCCPlan] = useState();
  const [standardPlan, setStandardPlan] = useState<PricingPlan | undefined>();
  const [growthCCPlanName, setGrowthCCPlanName] = useState("");

  const [currentPlan, setCurrentPlan] = useState<PricingPlan | undefined>();
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [currentPlanId, setCurrentPlanId] = useState("");
  const [currentPlanCode, setCurrentPlanCode] = useState(StatusCode.PERMISSION_DENIED);
  const [currentPlanEnumValue, setCurrentPlanEnumValue] = useState<PlanName | undefined>(undefined);

  const [growthFreePlanId, setGrowthFreePlanId] = useState("");
  const [growthCCPlanId, setGrowthCCPlanId] = useState("");
  const [scalePlanId, setScalePlanId] = useState("");

  const readCurrentCustomerPlan = async () => {
    try {
      const jwt = await getJwt();
      const response = await ReadCurrentCustomerPlan(jwt, AdminService, customer?.customerId ?? "");
      if (response.status?.code === StatusCode.OK) {
        setCurrentPlan(JSON.parse(response?.pricingPlanJson));
        setCurrentPlanName(response?.name || "Unknown");
        setCurrentPlanId(response?.customerPlanId || "Unknown");
        setCurrentPlanCode(StatusCode.OK);
        setCurrentPlanEnumValue(response?.planName);
      }
    } catch (err) {
      datadogRum.addError(err);
      console.log(err);
    }
  };

  const readGrowthCCPlan = async (planId: string) => {
    try {
      const response = await ReadCustomerPlanDetails(PublicAdminService, planId);
      if (response.status?.code === StatusCode.OK) {
        setGrowthCCPlan(JSON.parse(response?.pricingPlanJson));
        setGrowthCCPlanName(response?.name || "Growth (with CC)");
      }
    } catch (err) {
      datadogRum.addError(err);
      console.log(err);
    }
  };

  const readStandardPlan = async (planId: string) => {
    try {
      const response = await ReadCustomerPlanDetails(PublicAdminService, planId);
      if (response.status?.code === StatusCode.OK) {
        setStandardPlan(JSON.parse(response?.pricingPlanJson));
      } else {
        throw "Could not retrieve data for Standard pricing plan.";
      }
    } catch (err) {
      datadogRum.addError(err);
      console.log(err);
    }
  };

  const readPlanId = async (planName: PlanName) => {
    try {
      const response = await ReadCurrentIdOfPlan(PublicAdminService, planName);
      if (response.status?.code === StatusCode.OK) {
        return response.planId;
      }
    } catch (err) {
      datadogRum.addError(err);
      console.log(err);
      return "Unknown";
    }
  };

  /**
   * This will only get called when userContext changes which normally
   * happens when userSession is refreshed through cognito refresh token.
   */
  useEffect(() => {
    if (customer?.customerId) {
      if (userDetails?.userCredentials.isOwner || userDetails?.userCredentials.isBillingAdmin) {
        readCurrentCustomerPlan();
      }
    }
  }, [customer, AdminService, getJwt, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    readPlanId(PlanName.PLAN_NAME__GROWTH_FREE).then((planId) => setGrowthFreePlanId(planId || ""));
    readPlanId(PlanName.PLAN_NAME__SCALE).then((planId) => setScalePlanId(planId || ""));
    readPlanId(PlanName.PLAN_NAME__GROWTH_CC).then((planId) => {
      setGrowthCCPlanId(planId || "");
      readGrowthCCPlan(planId || "");
    });

    readPlanId(PlanName.PLAN_NAME__STANDARD).then((planId) => {
      readStandardPlan(planId || "");
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Calls the server to change the customer plan. Passes the requested plan id.
   * After a successful switch, updates the customer plan details so that frontend has
   * latest data after a re-render.
   */
  const switchPlan = async (newPlanName: PlanName, newPlanAlias: string) => {
    if (!userDetails?.userCredentials.isOwner && userDetails?.userCredentials.isBillingAdmin) {
      addNotification("You are not authorized to change the plan.", "danger");
      return;
    }

    const jwt = await getJwt();
    ChangeCustomerPlan(jwt, AdminService, customer?.customerId ?? "", newPlanName)
      .then((result) => {
        if (result.status?.code === StatusCode.OK) {
          if (result.planEffectiveTs <= Date.now() + 60 /* Adding a buffer of one minute */) {
            addNotification(`Your account plan was successfully changed to ${newPlanAlias}.`, "success");
          } else {
            const effectiveDate = getDueDate(result.planEffectiveTs);
            addNotification(`Your account plan will change to ${newPlanAlias} on ${effectiveDate}.`, "success");
          }
          // Following lines will cause the UI to update as per the new plan details.
          readCurrentCustomerPlan();
          if (result) updateEnabledFeatures();
        } else {
          if (result.status) addNotification(result.status.statusDetail, "danger");
        }
      })
      .catch((err) => {
        datadogRum.addError(err);
        console.log(err);
      });
  };

  const isOnFreePlan = currentPlanId === growthFreePlanId;
  // TODO: This is a temporary contract with the back-end for determining Scale users until
  // we have proper contract. https://vectara.slack.com/archives/C04866JGLCE/p1698180715540389?thread_ts=1698085292.025199&cid=C04866JGLCE
  const isOnScalePlan = enabledFeatures?.includes(FeatureType.FEATURE__CUSTOM_DIMENSIONS) ?? false;
  const isOnBundlePlan = currentPlan?.queryStorageIngestBundle === undefined ? false : true;

  const billingPlansWithoutSupport = [growthCCPlanId, growthFreePlanId];
  const isSupportAvailable = currentPlanId ? !billingPlansWithoutSupport.includes(currentPlanId) : false;
  const planEnumValue = currentPlanEnumValue;

  return (
    <PlanContext.Provider
      value={{
        currentPlan,
        currentPlanId,
        currentPlanName,
        currentPlanCode,
        growthCCPlan,
        growthCCPlanName,
        growthFreePlanId,
        growthCCPlanId,
        scalePlanId,
        standardPlan,
        switchPlan,
        isOnFreePlan,
        isOnScalePlan,
        isOnBundlePlan,
        isSupportAvailable,
        planEnumValue
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export const usePlanContext = () => {
  const context = useContext(PlanContext);
  if (context === undefined) {
    throw new Error("usePlanContext must be used within a PlanContextProvider");
  }
  return context;
};
