import { createContext, useContext, ReactNode, useRef } from "react";
import { CorporaState, CorporaStates, UploadConfig, UploadStatus } from "../types/corporaState";
import { uploadFile } from "../admin/UploadApi";
import { useUserContext } from "./UserContext";

type CorpusUploadStatus = "notStarted" | "complete" | "inProgress";

type UploadContextType = {
  getUploadQueue: (corpusKey: string) => UploadConfig[];
  getIsActive: (corpusKey: string) => boolean;
  getStatus: (corpusKey: string) => CorpusUploadStatus;
  addFilesToUploadQueue: (corpusKey: string, newFiles: UploadConfig[], queue: UploadConfig[]) => void;
  startUpload: (corpusKey: string, uploadQueue: UploadConfig[], isExtractTablesEnabled: boolean) => void;
  retryFailedFiles: (corpusKey: string, failedFiles: UploadConfig[], isExtractTablesEnabled: boolean) => void;
  removeFileFromUploadQueue: (corpusKey: string, file: UploadConfig) => void;
  resetUpload: (corpusKey: string) => void;
  stopUpload: (corpusKey: string) => void;
};

const UploadContext = createContext<UploadContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
  corporaStates: CorporaStates;
  setCorporaStates: React.Dispatch<React.SetStateAction<CorporaStates>>;
};

const getUploadStatus = (uploadState?: CorporaState["uploadFile"]): CorpusUploadStatus => {
  if (!uploadState) return "notStarted";

  if (!uploadState.isActive) {
    return "notStarted";
  }

  // If a single file is in progress, then the whole thing is in progress.
  if (uploadState.queue.find(({ status }) => status === "inProgress")) {
    return "inProgress";
  }

  return "complete";
};

export const UploadContextProvider = ({ children, corporaStates, setCorporaStates }: Props) => {
  const { getJwt, urls } = useUserContext();
  const corporaStatesRef = useRef(corporaStates);
  corporaStatesRef.current = corporaStates;

  const uploadUrl = urls?.uploadUrl ?? "";

  const getUploadingState = (corpusKey: string) => {
    return corporaStates[corpusKey]?.uploadFile;
  };

  const getStatus = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return getUploadStatus(state);
  };

  const getIsActive = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return state?.isActive ?? false;
  };

  const setIsActive = (corpusKey: string, isActive: boolean) => {
    setCorporaStates((prev) => {
      const prevCorpusState = prev[corpusKey] ?? {};
      const prevUploadingState = prevCorpusState.uploadFile;

      if (!prevUploadingState) return prev;

      return {
        ...prev,
        [corpusKey]: {
          ...prevCorpusState,
          uploadFile: {
            ...prevUploadingState,
            isActive
          }
        }
      };
    });
  };

  const resetUpload = (corpusKey: string) => {
    setCorporaStates((prev) => {
      const prevState = prev[corpusKey];
      if (!prevState) return prev;

      return {
        ...prev,
        [corpusKey]: {
          ...prevState,
          uploadFile: {
            isActive: false,
            queue: []
          }
        }
      };
    });
  };

  const getUploadQueue = (corpusKey: string) => {
    return getUploadingState(corpusKey)?.queue;
  };

  const createNewUploadQueue = (queue: UploadConfig[], targetQueue?: UploadConfig[]): UploadConfig[] => {
    const startingQueue = targetQueue ?? [];

    // Ignore duplicates.
    const mergedQueue = [...startingQueue, ...queue].reduce((acc, file) => {
      const existingFile = acc.find((f) => f.fullPath === file.fullPath);
      if (existingFile) {
        return acc;
      }
      return [...acc, file];
    }, [] as UploadConfig[]);

    // Reset status.
    return mergedQueue.map((file) => ({
      ...file,
      error: undefined,
      status: "notStarted"
    }));
  };

  const setUploadQueue = (corpusKey: string, queue: UploadConfig[]) => {
    setCorporaStates((prev) => {
      const prevCorpusState = prev[corpusKey] ?? {};
      const prevUploadingState = prevCorpusState.uploadFile;

      return {
        ...prev,
        [corpusKey]: {
          ...prevCorpusState,
          uploadFile: {
            ...(prevUploadingState ?? {}),
            queue
          }
        }
      };
    });
  };

  const setFileStatus = (corpusKey: string, uploadConfig: UploadConfig, uploadStatus: UploadStatus, error?: any) => {
    setCorporaStates((prev) => {
      const prevState = prev[corpusKey];

      const updatedQueue = prevState.uploadFile.queue.map((file) => {
        if (file.fullPath === uploadConfig.fullPath) {
          return {
            ...file,
            status: uploadStatus,
            error
          };
        }
        return file;
      });

      return {
        ...prev,
        [corpusKey]: {
          ...prevState,
          uploadFile: {
            ...prevState.uploadFile,
            queue: updatedQueue
          }
        }
      };
    });
  };

  const addFilesToUploadQueue = (corpusKey: string, newFiles: UploadConfig[], queue: UploadConfig[]) => {
    const newUploadQueue = createNewUploadQueue(newFiles, queue);
    setUploadQueue(corpusKey, newUploadQueue);
  };

  const removeFileFromUploadQueue = (corpusKey: string, file: UploadConfig) => {
    const newUploadQueue = getUploadQueue(corpusKey).filter((f) => f.fullPath !== file.fullPath);
    setUploadQueue(corpusKey, newUploadQueue);
  };

  const startUpload = (corpusKey: string, uploadQueue: UploadConfig[], isExtractTablesEnabled: boolean) => {
    setIsActive(corpusKey, true);
    uploadQueue.forEach((item) => {
      if (item.status !== "success") {
        item.status = "notStarted";
      }
    });

    // This number depends on how many encoders are available on the back-end.
    const MAX_PARALLEL_UPLOADS = 8;
    let numParallelUploads = 0;

    const start = async () => {
      let isComplete = false;
      while (!isComplete && numParallelUploads < MAX_PARALLEL_UPLOADS) {
        // Work through files in the order in which they were added.
        const nextUpload = uploadQueue.find(({ status }) => status === "notStarted");

        if (nextUpload) {
          numParallelUploads++;
          nextUpload.status = "inProgress";
          setFileStatus(corpusKey, nextUpload, "inProgress");

          // Couple generation of the JWT with generation of the request,
          // so that the JWT is as fresh as possible. Otherwise we risk
          // the JWT expiring before the request is sent.
          const jwt = await getJwt();

          const upload = uploadFile({
            upload: nextUpload.upload,
            uploadUrl,
            jwt,
            isExtractTablesEnabled,
            onComplete: setFileStatus.bind(null, corpusKey, nextUpload)
          });

          upload.then(() => {
            numParallelUploads--;
            start();
          });
        } else {
          isComplete = true;
        }
      }
    };

    start();
  };

  const retryFailedFiles = (corpusKey: string, failedFiles: UploadConfig[], isExtractTablesEnabled: boolean) => {
    const newUploadQueue = createNewUploadQueue(failedFiles);
    setUploadQueue(corpusKey, newUploadQueue);
    startUpload(corpusKey, newUploadQueue, isExtractTablesEnabled);
  };

  const stopUpload = (corpusKey: string) => {
    const uploadQueue = getUploadQueue(corpusKey);

    uploadQueue.forEach((item) => {
      const { status, upload } = item;
      if (status !== "success" && status !== "error") {
        upload?.request.abort();
        item.status = "notStarted";
      }
    });

    setUploadQueue(corpusKey, uploadQueue);
    setIsActive(corpusKey, false);
  };

  return (
    <UploadContext.Provider
      value={{
        getUploadQueue,
        getIsActive,
        getStatus,
        addFilesToUploadQueue,
        startUpload,
        retryFailedFiles,
        removeFileFromUploadQueue,
        resetUpload,
        stopUpload
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error("useUploadContext must be used within a UploadContextProvider");
  }
  return context;
};
