import { UploadConfig, UploadStatus } from "../types/corporaState";
import { isHttpResponseCodeError } from "../utils/isHttpResponseCodeError";

export const generateUploadRequest = ({
  file,
  customerId,
  corpusId
}: {
  file: File;
  customerId: string;
  corpusId: number;
}): UploadConfig["upload"] => ({
  request: new XMLHttpRequest(),
  data: {
    customerId,
    corpusId: corpusId.toString(),
    fileOrBlob: file
  }
});

export const uploadFile = async ({
  upload,
  uploadUrl,
  jwt,
  isExtractTablesEnabled,
  onComplete
}: {
  upload: UploadConfig["upload"];
  uploadUrl: string;
  jwt: string;
  isExtractTablesEnabled: boolean;
  onComplete: (uploadStatus: UploadStatus, error?: any) => void;
}) => {
  await new Promise((resolve) => {
    if (upload && uploadUrl) {
      const {
        request,
        data: { customerId, corpusId, fileOrBlob }
      } = upload;

      const formData = new FormData();

      formData.append("c", customerId);
      formData.append("o", corpusId.toString());

      // HTML file input, chosen by user
      if (fileOrBlob instanceof File) formData.append("p", fileOrBlob.webkitRelativePath);
      formData.append("file", fileOrBlob);

      // Text extraction config.
      formData.append(
        "table_extraction_config",
        new Blob(
          [
            JSON.stringify({
              extract_tables: isExtractTablesEnabled
            })
          ],
          {
            type: "application/json"
          }
        )
      );

      const handleError = () => {
        const { responseText, status: statusCode } = request;
        const { error } = extractError(responseText, statusCode);

        onComplete("error", error);
      };

      request.open("POST", uploadUrl);
      request.setRequestHeader("Authorization", `Bearer ${jwt}`);

      request.onload = () => {
        if (request.status !== 200) {
          handleError();
        } else {
          onComplete("success");
        }
        resolve(undefined);
      };

      request.onerror = () => {
        handleError();
        resolve(undefined);
      };

      request.send(formData);
    }
  });
};

export const uploadSampleData = async (
  customerId: string,
  corpusId: number,
  uploadUrl: string,
  jwt: string,
  onComplete: (uploadStatus: UploadStatus) => void
) => {
  const response = await fetch("/files/black_hole.html", {
    method: "GET",
    headers: {
      Accept: "application/octet-stream"
    }
  });

  if (isHttpResponseCodeError(response.status)) {
    throw "Could not retrieve sample data.";
  }

  const data = await response.arrayBuffer();
  const blob = new Blob([data]);

  uploadFile({
    upload: {
      request: new XMLHttpRequest(),
      data: {
        customerId,
        corpusId: corpusId.toString(),
        fileOrBlob: blob
      }
    },
    uploadUrl,
    jwt,
    isExtractTablesEnabled: false,
    onComplete
  });
};

const extractError = (responseText: string, statusCode: number) => {
  try {
    const { httpCode: status, details } = JSON.parse(responseText);
    return {
      error: {
        msg: details ?? generateErrorMessage(status || statusCode),
        code: status ?? statusCode
      }
    };
  } catch (e) {
    return {
      error: {
        msg:
          generateErrorMessage(statusCode) ??
          responseText?.toString()?.substr(0, 100) + "..." ??
          "Error parsing server response",
        code: statusCode ?? ""
      }
    };
  }
};

export const generateErrorMessage = (code: number) => {
  switch (code) {
    case 0:
      return "Connection to the server was lost.";

    case 400:
      return "Bad request could not be processed.";

    case 401:
      return "Unauthorized for upload.";

    case 403:
      return "Unauthenticated. Please log in again.";

    case 405:
      return "Corpus is disabled. Enable it to upload files";

    case 409:
      return "Conflict: A different document with the same ID already exists.";

    case 415:
      return "Unsupported document type.";

    case 500:
      return "Internal server error.";

    case 504:
      return "Request timed out.";

    case 507:
      return "Insufficient storage to upload.";

    case 429:
      return "Too many requests. Try later.";

    default:
      return "";
  }
};
