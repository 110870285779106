// source: indexing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var custom_dim_pb = require('./custom_dim_pb.js');
goog.object.extend(proto, custom_dim_pb);
var table_pb = require('./table_pb.js');
goog.object.extend(proto, table_pb);
goog.exportSymbol('proto.com.vectara.indexing.ChunkingStrategy', null, global);
goog.exportSymbol('proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy', null, global);
goog.exportSymbol('proto.com.vectara.indexing.ChunkingStrategy.StrategyCase', null, global);
goog.exportSymbol('proto.com.vectara.indexing.Document', null, global);
goog.exportSymbol('proto.com.vectara.indexing.Section', null, global);
goog.exportSymbol('proto.com.vectara.indexing.TableExtractionConfig', null, global);
goog.exportSymbol('proto.com.vectara.indexing.TableSummarizationOptions', null, global);
goog.exportSymbol('proto.com.vectara.indexing.TableSummarizationOptions.ModelParams', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.Section = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.indexing.Section.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.indexing.Section, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.Section.displayName = 'proto.com.vectara.indexing.Section';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.indexing.Document.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.indexing.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.Document.displayName = 'proto.com.vectara.indexing.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.ChunkingStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.indexing.ChunkingStrategy.oneofGroups_);
};
goog.inherits(proto.com.vectara.indexing.ChunkingStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.ChunkingStrategy.displayName = 'proto.com.vectara.indexing.ChunkingStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.displayName = 'proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.TableSummarizationOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.indexing.TableSummarizationOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.TableSummarizationOptions.displayName = 'proto.com.vectara.indexing.TableSummarizationOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.indexing.TableSummarizationOptions.ModelParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.displayName = 'proto.com.vectara.indexing.TableSummarizationOptions.ModelParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.TableExtractionConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.indexing.TableExtractionConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.TableExtractionConfig.displayName = 'proto.com.vectara.indexing.TableExtractionConfig';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.indexing.Section.repeatedFields_ = [5,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.Section.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.Section.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.Section} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Section.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    metadataJson: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customDimsList: jspb.Message.toObjectList(msg.getCustomDimsList(),
    custom_dim_pb.CustomDimension.toObject, includeInstance),
    sectionList: jspb.Message.toObjectList(msg.getSectionList(),
    proto.com.vectara.indexing.Section.toObject, includeInstance),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    table_pb.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.Section}
 */
proto.com.vectara.indexing.Section.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.Section;
  return proto.com.vectara.indexing.Section.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.Section} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.Section}
 */
proto.com.vectara.indexing.Section.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadataJson(value);
      break;
    case 5:
      var value = new custom_dim_pb.CustomDimension;
      reader.readMessage(value,custom_dim_pb.CustomDimension.deserializeBinaryFromReader);
      msg.addCustomDims(value);
      break;
    case 10:
      var value = new proto.com.vectara.indexing.Section;
      reader.readMessage(value,proto.com.vectara.indexing.Section.deserializeBinaryFromReader);
      msg.addSection(value);
      break;
    case 11:
      var value = new table_pb.Table;
      reader.readMessage(value,table_pb.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.Section.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.Section.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.Section} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Section.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMetadataJson();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomDimsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      custom_dim_pb.CustomDimension.serializeBinaryToWriter
    );
  }
  f = message.getSectionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.vectara.indexing.Section.serializeBinaryToWriter
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      table_pb.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.com.vectara.indexing.Section.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.Section} returns this
 */
proto.com.vectara.indexing.Section.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.vectara.indexing.Section.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Section} returns this
 */
proto.com.vectara.indexing.Section.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.com.vectara.indexing.Section.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Section} returns this
 */
proto.com.vectara.indexing.Section.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string metadata_json = 4;
 * @return {string}
 */
proto.com.vectara.indexing.Section.prototype.getMetadataJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Section} returns this
 */
proto.com.vectara.indexing.Section.prototype.setMetadataJson = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CustomDimension custom_dims = 5;
 * @return {!Array<!proto.com.vectara.indexing.CustomDimension>}
 */
proto.com.vectara.indexing.Section.prototype.getCustomDimsList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.CustomDimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, custom_dim_pb.CustomDimension, 5));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.CustomDimension>} value
 * @return {!proto.com.vectara.indexing.Section} returns this
*/
proto.com.vectara.indexing.Section.prototype.setCustomDimsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.indexing.CustomDimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.CustomDimension}
 */
proto.com.vectara.indexing.Section.prototype.addCustomDims = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.indexing.CustomDimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Section} returns this
 */
proto.com.vectara.indexing.Section.prototype.clearCustomDimsList = function() {
  return this.setCustomDimsList([]);
};


/**
 * repeated Section section = 10;
 * @return {!Array<!proto.com.vectara.indexing.Section>}
 */
proto.com.vectara.indexing.Section.prototype.getSectionList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.Section>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.indexing.Section, 10));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.Section>} value
 * @return {!proto.com.vectara.indexing.Section} returns this
*/
proto.com.vectara.indexing.Section.prototype.setSectionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.vectara.indexing.Section=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.Section}
 */
proto.com.vectara.indexing.Section.prototype.addSection = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.vectara.indexing.Section, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Section} returns this
 */
proto.com.vectara.indexing.Section.prototype.clearSectionList = function() {
  return this.setSectionList([]);
};


/**
 * repeated Table table = 11;
 * @return {!Array<!proto.com.vectara.indexing.Table>}
 */
proto.com.vectara.indexing.Section.prototype.getTableList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, table_pb.Table, 11));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.Table>} value
 * @return {!proto.com.vectara.indexing.Section} returns this
*/
proto.com.vectara.indexing.Section.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.vectara.indexing.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.Table}
 */
proto.com.vectara.indexing.Section.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.vectara.indexing.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Section} returns this
 */
proto.com.vectara.indexing.Section.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.indexing.Document.repeatedFields_ = [5,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    metadataJson: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customDimsList: jspb.Message.toObjectList(msg.getCustomDimsList(),
    custom_dim_pb.CustomDimension.toObject, includeInstance),
    sectionList: jspb.Message.toObjectList(msg.getSectionList(),
    proto.com.vectara.indexing.Section.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.Document}
 */
proto.com.vectara.indexing.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.Document;
  return proto.com.vectara.indexing.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.Document}
 */
proto.com.vectara.indexing.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadataJson(value);
      break;
    case 5:
      var value = new custom_dim_pb.CustomDimension;
      reader.readMessage(value,custom_dim_pb.CustomDimension.deserializeBinaryFromReader);
      msg.addCustomDims(value);
      break;
    case 10:
      var value = new proto.com.vectara.indexing.Section;
      reader.readMessage(value,proto.com.vectara.indexing.Section.deserializeBinaryFromReader);
      msg.addSection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMetadataJson();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomDimsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      custom_dim_pb.CustomDimension.serializeBinaryToWriter
    );
  }
  f = message.getSectionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.vectara.indexing.Section.serializeBinaryToWriter
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.com.vectara.indexing.Document.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Document} returns this
 */
proto.com.vectara.indexing.Document.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.vectara.indexing.Document.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Document} returns this
 */
proto.com.vectara.indexing.Document.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.vectara.indexing.Document.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Document} returns this
 */
proto.com.vectara.indexing.Document.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string metadata_json = 4;
 * @return {string}
 */
proto.com.vectara.indexing.Document.prototype.getMetadataJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Document} returns this
 */
proto.com.vectara.indexing.Document.prototype.setMetadataJson = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CustomDimension custom_dims = 5;
 * @return {!Array<!proto.com.vectara.indexing.CustomDimension>}
 */
proto.com.vectara.indexing.Document.prototype.getCustomDimsList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.CustomDimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, custom_dim_pb.CustomDimension, 5));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.CustomDimension>} value
 * @return {!proto.com.vectara.indexing.Document} returns this
*/
proto.com.vectara.indexing.Document.prototype.setCustomDimsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.indexing.CustomDimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.CustomDimension}
 */
proto.com.vectara.indexing.Document.prototype.addCustomDims = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.indexing.CustomDimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Document} returns this
 */
proto.com.vectara.indexing.Document.prototype.clearCustomDimsList = function() {
  return this.setCustomDimsList([]);
};


/**
 * repeated Section section = 10;
 * @return {!Array<!proto.com.vectara.indexing.Section>}
 */
proto.com.vectara.indexing.Document.prototype.getSectionList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.Section>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.indexing.Section, 10));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.Section>} value
 * @return {!proto.com.vectara.indexing.Document} returns this
*/
proto.com.vectara.indexing.Document.prototype.setSectionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.vectara.indexing.Section=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.Section}
 */
proto.com.vectara.indexing.Document.prototype.addSection = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.vectara.indexing.Section, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Document} returns this
 */
proto.com.vectara.indexing.Document.prototype.clearSectionList = function() {
  return this.setSectionList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.indexing.ChunkingStrategy.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.com.vectara.indexing.ChunkingStrategy.StrategyCase = {
  STRATEGY_NOT_SET: 0,
  MAX_CHARS_CHUNKING: 1
};

/**
 * @return {proto.com.vectara.indexing.ChunkingStrategy.StrategyCase}
 */
proto.com.vectara.indexing.ChunkingStrategy.prototype.getStrategyCase = function() {
  return /** @type {proto.com.vectara.indexing.ChunkingStrategy.StrategyCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.indexing.ChunkingStrategy.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.ChunkingStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.ChunkingStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.ChunkingStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.ChunkingStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxCharsChunking: (f = msg.getMaxCharsChunking()) && proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.ChunkingStrategy}
 */
proto.com.vectara.indexing.ChunkingStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.ChunkingStrategy;
  return proto.com.vectara.indexing.ChunkingStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.ChunkingStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.ChunkingStrategy}
 */
proto.com.vectara.indexing.ChunkingStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy;
      reader.readMessage(value,proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.deserializeBinaryFromReader);
      msg.setMaxCharsChunking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.ChunkingStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.ChunkingStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.ChunkingStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.ChunkingStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxCharsChunking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxCharsPerChunk: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy}
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy;
  return proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy}
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCharsPerChunk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxCharsPerChunk();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 max_chars_per_chunk = 5;
 * @return {number}
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.prototype.getMaxCharsPerChunk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy} returns this
 */
proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy.prototype.setMaxCharsPerChunk = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional MaxCharsChunkingStrategy max_chars_chunking = 1;
 * @return {?proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy}
 */
proto.com.vectara.indexing.ChunkingStrategy.prototype.getMaxCharsChunking = function() {
  return /** @type{?proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy, 1));
};


/**
 * @param {?proto.com.vectara.indexing.ChunkingStrategy.MaxCharsChunkingStrategy|undefined} value
 * @return {!proto.com.vectara.indexing.ChunkingStrategy} returns this
*/
proto.com.vectara.indexing.ChunkingStrategy.prototype.setMaxCharsChunking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.vectara.indexing.ChunkingStrategy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.indexing.ChunkingStrategy} returns this
 */
proto.com.vectara.indexing.ChunkingStrategy.prototype.clearMaxCharsChunking = function() {
  return this.setMaxCharsChunking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.ChunkingStrategy.prototype.hasMaxCharsChunking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.TableSummarizationOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.TableSummarizationOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.TableSummarizationOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    promptText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    responseLang: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelParams: (f = msg.getModelParams()) && proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions}
 */
proto.com.vectara.indexing.TableSummarizationOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.TableSummarizationOptions;
  return proto.com.vectara.indexing.TableSummarizationOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.TableSummarizationOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions}
 */
proto.com.vectara.indexing.TableSummarizationOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromptText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseLang(value);
      break;
    case 3:
      var value = new proto.com.vectara.indexing.TableSummarizationOptions.ModelParams;
      reader.readMessage(value,proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.deserializeBinaryFromReader);
      msg.setModelParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.TableSummarizationOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.TableSummarizationOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.TableSummarizationOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromptText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResponseLang();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelParams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxOutputTokens: jspb.Message.getFieldWithDefault(msg, 2, 0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    frequencyPenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    presencePenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    seed: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.TableSummarizationOptions.ModelParams;
  return proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxOutputTokens(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrequencyPenalty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPresencePenalty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional string model = 1;
 * @return {string}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.setModel = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.clearModel = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 max_output_tokens = 2;
 * @return {number}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.getMaxOutputTokens = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.setMaxOutputTokens = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.clearMaxOutputTokens = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.hasMaxOutputTokens = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float temperature = 3;
 * @return {number}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.setTemperature = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.clearTemperature = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float frequency_penalty = 4;
 * @return {number}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.getFrequencyPenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.setFrequencyPenalty = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.clearFrequencyPenalty = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.hasFrequencyPenalty = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float presence_penalty = 5;
 * @return {number}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.getPresencePenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.setPresencePenalty = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.clearPresencePenalty = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.hasPresencePenalty = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 seed = 6;
 * @return {number}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.getSeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.setSeed = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.clearSeed = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.TableSummarizationOptions.ModelParams.prototype.hasSeed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string prompt_text = 1;
 * @return {string}
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.getPromptText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.setPromptText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string response_lang = 2;
 * @return {string}
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.getResponseLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.setResponseLang = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ModelParams model_params = 3;
 * @return {?proto.com.vectara.indexing.TableSummarizationOptions.ModelParams}
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.getModelParams = function() {
  return /** @type{?proto.com.vectara.indexing.TableSummarizationOptions.ModelParams} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.indexing.TableSummarizationOptions.ModelParams, 3));
};


/**
 * @param {?proto.com.vectara.indexing.TableSummarizationOptions.ModelParams|undefined} value
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions} returns this
*/
proto.com.vectara.indexing.TableSummarizationOptions.prototype.setModelParams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.indexing.TableSummarizationOptions} returns this
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.clearModelParams = function() {
  return this.setModelParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.TableSummarizationOptions.prototype.hasModelParams = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.TableExtractionConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.TableExtractionConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.TableExtractionConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.TableExtractionConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    extractTables: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.TableExtractionConfig}
 */
proto.com.vectara.indexing.TableExtractionConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.TableExtractionConfig;
  return proto.com.vectara.indexing.TableExtractionConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.TableExtractionConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.TableExtractionConfig}
 */
proto.com.vectara.indexing.TableExtractionConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtractTables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.TableExtractionConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.TableExtractionConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.TableExtractionConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.TableExtractionConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtractTables();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool extract_tables = 1;
 * @return {boolean}
 */
proto.com.vectara.indexing.TableExtractionConfig.prototype.getExtractTables = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.indexing.TableExtractionConfig} returns this
 */
proto.com.vectara.indexing.TableExtractionConfig.prototype.setExtractTables = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.com.vectara.indexing);
