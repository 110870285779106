import { apiV2Client } from "./apiV2Client";

// The Jobs API returns old jobs that have failed and aren't current.
// We'll prevent these jobs from appearing in the UI.
const CURRENT_JOBS = ["queued", "started"] as const;
export type CurrentJobState = (typeof CURRENT_JOBS)[number];

export const sendFetchJobsRequest = async (jwt: string, customerId: string, corpusKeys: string[]) => {
  const { data, error } = await apiV2Client.GET("/v2/jobs", {
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    params: {
      query: {
        state: [...CURRENT_JOBS],
        corpus_key: corpusKeys
      }
    }
  });

  if (error) {
    throw error;
  }

  return data;
};
